<template>
    <div class="my_treat_page">
        <div class="name_info">
            <div class="name_num">
                <span class="left">{{detailinfo.name}}</span>
                <span class="right" v-if="detailinfo.appointmentnum">{{detailinfo.appointmentnum}}</span>
            </div>
            <div class="account_info">
                <div class="sex_age_phone">
                    <span>{{detailinfo.sex}} . {{detailinfo.age}}岁</span>
                    <span v-if="detailinfo.phone">{{detailinfo.phone}}</span>
                </div>
                <div class="account">
                    <span>工号：{{dispname}}</span>
                </div>
            </div>

        </div>
        <div class="main_info">
            <div class="info_line">
                <span>开单诊所</span>
                <p>{{detailinfo.clinicname  || '--'}}</p>
            </div>
            <div class="info_line">
                <span>开单医生</span>
                <p>{{detailinfo.doctor  || '--'}}</p>
            </div>
            <div class="info_line">
                <span>预约时间</span>
                <p>{{detailinfo.appointmenttime}}</p>
            </div>
            <div class="info_line">
                <span>理疗名称</span>
                <p :class="'status'+detailinfo.execstatuscode">{{detailinfo.projectname}}，{{detailinfo.execstatusname}}</p>
            </div>
            <div class="info_line">
                <span>医生备注</span>
                <p>{{detailinfo.doctorremark || '--'}}</p>
            </div>
            <div class="info_line">
                <span>执行诊所</span>
                <p>{{detailinfo.execclinicname}}</p>
            </div>
            <div class="info_line">
                <span>执行人</span>
                <p>{{detailinfo.execdoctorname}}</p>
            </div>
            <div class="info_line">
                <span>执行诊室</span>
                <p>{{detailinfo.roomname}}</p>
            </div>
            <div class="info_line">
                <span>执行时间</span>
                <p>{{detailinfo.exectime }}</p>
            </div>
            <div class="info_line" v-if="detailinfo.userid !== detailinfo.useuserid || detailinfo.customerid !== detailinfo.usecustomerid">
                <span>扣费账号</span>
                <p class="status2">{{detailinfo.feeaccount}}</p>
            </div>
            <div class="info_line">
                <span>签名时间</span>
                <p v-if="detailinfo.signaturetime">{{detailinfo.signaturetime || '--'}}</p>
                <div v-else style="color: #FA6400;display: flex;justify-content: flex-end;">
                    <p>未签名</p>
                    <a style="color: #2d8cf0;width: 60px;"  @click="changeRouter">  去签名</a>
                </div>
            </div>
            <div class="info_line">
                <span>签名图片</span>
                <p v-if="detailinfo.signaturetime" style="display: flex;justify-content: flex-end;">
                    
                    <img  :src="minimg" alt=""  @click="showImage(detailinfo.signatureimg)">
                    <!-- <img  :src="detailinfo.signatureimg" alt="" @click="showImage(detailinfo.signatureimg)"> -->
                </p>
                <p v-else>--</p>
            </div>
            <div class="info_line">
                <span>理疗备注</span>
                <p>{{detailinfo.treatremark || '--'}}</p>
            </div>
            <div class="info_line">
                <span>配穴方案</span>
                <div>{{detailinfo.acupointdesc || '--'}}</div>
            </div>
        </div>
        <div class="btn_detail_info">
            <div class="btn_div" @click="medical">
                <i class="icon iconfont iconbingli"></i>
                <p>患者病历</p>
            </div>
            <div class="btn_div" @click="treat">
                <i class="icon iconfont iconyoujian"></i>
                <p>理疗记录</p>
            </div>
            <div class="btn_div" @click="check">
                <i class="icon iconfont iconshijian"></i>
                <p>检查历史</p>
            </div>
        </div>

        <div class="exec_list" v-if="execlist.length > 0">
            <div class="exec_th">
                <span>理疗名称</span>
                <span>执行人</span>
                <span>执行状态</span>
                <span>操作</span>
            </div>
            <div class="one" v-for="item in execlist" :key="item.id">
                <div class="exec_td">
                    <span>{{item.projectname}}</span>
                    <span>{{item.execdoctorname}} {{item.isown == '1' ? '(本人)': '(他人)'}}</span>
                    <span :class="'status' + item.execstatuscode">{{item.execstatusname}}</span>
                    <span v-if="item.execstatuscode === 2 || item.execstatuscode === 3" @click="match(item.id,item.execstatuscode, item.acupointids)">配穴</span>
                    <span v-else>--</span>
                </div>
            </div>
        </div>

         <div class="ctrl_bottom" v-if="show">
             <button @click="back">返回</button>
             <button @click="cancelProject" v-if="detailinfo.execstatuscode === '2' || detailinfo.execstatuscode === '3'">取消执行</button>
             <button @click="again" v-if="detailinfo.execstatuscode === '2' || detailinfo.execstatuscode === '3'">重新分诊</button>
             <button class="exec_class" @click="nowExec" v-if="detailinfo.execstatuscode === '2'" >立即执行</button>
             <button class="exec_class" @click="finish" v-if="detailinfo.execstatuscode === '3'">完成</button>
        </div>

        <van-dialog v-model="showimg" >
            <img :src="detailinfo.signatureimg"  style="object-fit: contain;height: auto;width: 100%;padding: 20px;"/>
        </van-dialog>
        
    </div>
</template>

<script>
    import { wxtreatdetail,wxtreatopr ,waitsignaturelist} from '@/api/medical'
    import { mapGetters } from 'vuex'
    import { Toast ,Dialog} from 'vant'
    export default {
        computed: {
            ...mapGetters([
                'username','dispname','clinicid','isrefresh'
            ]),
        },
        data() {
            return {
                execstatus: '0',
                serviceid: '',
                doctor:'',
                roomid:'',
                doctorname: '',
                detailinfo: {},
                execlist:[],
                userid: '',
                customerid:'',
                name:'',
                sex:'',
                age:'',
                phone:'',
                show: true,
                showimg:false,
                minimg:'',
                signaturelist:[],
            }
        },
        mounted () {
            this.$store.dispatch('hideOrShowNav', false)
            
            this.getDetailInfo()   
        },
        created(){
            this.serviceid = this.$route.query.serviceid || '';
            this.searchList()
        },
        methods: {
            // 获取订单信息
            getDetailInfo(){
                Toast.loading({
                    message: '加载中...',
                    forbidClick: true,
                })
                let data = {
                    username: this.username,
                    serviceid: this.serviceid,
                    clinicid:this.clinicid
                }
                wxtreatdetail(data).then(response => {
                    Toast.clear()
                    if(response.response_body.signatureimg){

                        this.resizeImage(response.response_body.signatureimg)
                     }
                    this.detailinfo = response.response_body
                    this.execlist = response.response_body.execlist
                    this.execstatus = response.response_body.execstatuscode
                    this.doctor = response.response_body.execdoctorcode
                    this.roomid = response.response_body.roomid
                    this.doctorname = response.response_body.execdoctorname
                    this.name = response.response_body.name
                    this.sex = response.response_body.sex
                    this.age = response.response_body.age
                    this.phone = response.response_body.phone
                    this.userid = response.response_body.userid
                    this.customerid = response.response_body.customerid
                    
                })
            },
            //返回
            back() {
                if (window.history.length <= 1) {
                    this.$router.push({path:'/'});
                    return false
                } else {
                    this.$router.go(-1);
                }
                // this.$router.go(-1)
            },
            // 取消执行
            cancelProject(){
                this.$dialog.confirm({
                    title: null,
                    message: '确认取消本次分诊操作？',
                })
                .then(() => {
                    let data = {
                        username: this.username,
                        oprtype: '0',
                        serviceid: this.serviceid
                    }
                    wxtreatopr(data).then(() => {
                        this.$store.dispatch('changeIsRefresh', '1')
                        Toast.success('已成功取消本次分诊!');
                        this.show = false
                        let second = 2;
                        const timer = setInterval(() => {
                            second--;
                            if (second === 0) {
                                clearInterval(timer);
                                this.$router.go(-1)
                            }
                        }, 1000);

                    })
                })
                .catch(() => {
                    // on cancel
                });
            },
            // 立即执行
            nowExec(){
                this.$dialog.confirm({
                    title: null,
                    message:this.detailinfo.signaturetime ? '确认执行本次项目操作？' : '该项目患者还未签名，是否确认执行？',
                })
                    .then(() => {
                        let data = {
                            username: this.username,
                            oprtype: '2',
                            serviceid: this.serviceid
                        }
                        wxtreatopr(data).then(() => {
                            this.$store.dispatch('changeIsRefresh', '1')
                            Toast.success('已执行成功!');
                            this.show = false
                            let second = 2;
                            const timer = setInterval(() => {
                                second--;
                                if (second === 0) {
                                    clearInterval(timer);
                                    location.reload()
                                }
                            }, 1000);

                        })
                    })
                    .catch(() => {
                        // on cancel
                    });
            },
            //重新分诊
            again() {
                this.$router.push({
                    path: '/treatExec',
                    query: {
                        serviceid: this.serviceid,
                        oprtype: '1',
                        doctor: this.doctor,
                        roomid: this.roomid,
                        doctorname: this.doctorname
                    }
                })
            },
            //完成
            finish() {
    
                if(this.detailinfo.signaturetime == undefined){
                    Toast('该理疗未签名，不允许提交')
                    return
                }
                
                let str = this.detailinfo.execlist.filter(item => item.projectcode == this.detailinfo.projectcode)
       
                if(str[0].isown != '1'){
                    Toast('操作失败，不是操作本人，请重新分诊')
                    return
                }
                this.$router.push({
                    path: '/treatExec',
                    query: {
                        serviceid: this.serviceid,
                        oprtype: '3',
                        doctor: this.doctor,
                        roomid: this.roomid,
                        doctorname: this.doctorname
                    }
                })
            },
            medical() {
                this.$store.dispatch('changeIsRefresh', '1')
                this.$router.push({
                    path: '/medicalPage',
                    query: {
                        userid: this.detailinfo.useuserid,
                        customerid: this.detailinfo.usecustomerid,
                    }
                })
            },
            treat() {
                this.$store.dispatch('changeIsRefresh', '1')
                this.$router.push({
                    path: '/historyPage',
                    query: {
                        userid: this.detailinfo.useuserid,
                        customerid: this.detailinfo.usecustomerid,
                        name: this.name,
                        sex: this.sex,
                        age: this.age,
                        phone: this.phone
                    }
                })
            },
            check() {
                this.$store.dispatch('changeIsRefresh', '1')
                this.$router.push({
                    path: '/checkPage',
                    query: {
                        userid:  this.detailinfo.useuserid,
                        customerid: this.detailinfo.usecustomerid,
                        name: this.name,
                        sex: this.sex,
                        age: this.age,
                        phone: this.phone
                    }
                })
            },
            match(id, execstatus, acupointids) {
                this.$router.push({
                    path: '/matchAcupoint',
                    query: {
                        serviceid: id,
                        userid: this.userid,
                        customerid: this.customerid,
                        name: this.name,
                        sex: this.sex,
                        age: this.age,
                        phone: this.phone,
                        execstatus: execstatus,
                        acupointids: acupointids,
                    }
                })
            },
            // 待签名服务查询  waitsignaturelist
            // 查询待签名列表
            searchList(){
                let value = {
                    username: this.username,
                    serviceid: this.serviceid,
                };
                waitsignaturelist(value).then(res =>{
       
                    this.signaturelist = res.response_body.list
                })
            },
            changeRouter(){
                var arr = [...this.signaturelist]
              
                let datas = arr.filter(item => item.projectcode != this.detailinfo.projectcode).map(item => item.projectname )

                if(datas.length > 0){

                    Dialog.confirm({
                        title: '提示',
                        message: `患者当天还存在其他理疗项目（${datas.join('、')}）未签名，是否同步签名`,
                        confirmButtonText:'全部签名',
                        cancelButtonText:'仅签名当前项目',
                    })
                    .then(() => {
                        // on confirm
                        this.$router.push({
                            path:'/signature',
                            query:{
                                serviceid:this.serviceid ,
                                isAll:'true',
                            }
                        })
                    })
                    .catch(() => {
                        // on cancel
                        this.$router.push({
                            path:'/signature',
                            query:{
                                serviceid:this.serviceid ,
                                isAll:'false',
                            }
                        })
                    });
                }else{
                    this.$router.push({
                        path:'/signature',
                        query:{
                            serviceid:this.serviceid ,
                            isAll:'false',
                        }
                    })
                }
                
                
            },
            showImage(img){
                this.showimg = true
            },
            resizeImage(base64String) {
                let maxWidth = 100
                let maxHeight = 40
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");
                
                const img = new Image();
                // img.crossOrigin= 'anonymous'
                img.src = base64String ;
                let that = this
                img.onload = function() {
                  
                        let scaleFactor = 1; // 初始化缩放因子为1（不缩放）
                        
                        if (img.width > maxWidth || img.height > maxHeight) {
                            // 计算缩放因子
                            scaleFactor = Math.min((maxWidth / img.width), (maxHeight / img.height));
                            
                            // 重新设定canvas的宽高
                            canvas.width = img.width * scaleFactor;
                            canvas.height = img.height * scaleFactor;
                        } else {
                            canvas.width = img.width;
                            canvas.height = img.height;
                        }
                       
                    // 清空canvas内容
                    context.clearRect(0, 0, canvas.width, canvas.height);
                    
                    // 绘制缩放后的图片
                    context.drawImage(img, 0, 0, canvas.width, canvas.height);
                    
                    // 输出结果
                    
                    
                     that.minimg = canvas.toDataURL();
                    //  alert(this.minimg)
                    //  return minimg
                    };
                    
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>